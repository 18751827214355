'use strict'

document.addEventListener('DOMContentLoaded', function() {
  var width = window.innerWidth;
  setTimeout(function(){
    document.body.className="loaded";
  },500);
  if (width > 1024) {
    var offsetHeight = document.getElementById('homepage__info').offsetHeight;
    document.getElementById('homepage__picture').style.height = offsetHeight+'px';
  }

  const background = document.querySelector('.background');
  const button = document.querySelector('.navbar__button');
  const back = document.getElementById('navbar__back');
  const navbar = document.getElementById('navbar');
  const navbarHeight = navbar.offsetHeight;
  const navbarNav = document.getElementsByClassName('navbar__navigation')[0];
  const navbarNavHeight = navbarNav.offsetHeight;
  const navbarMargins = (navbarHeight - navbarNavHeight) / 2;
  navbarNav.style.marginTop = navbarMargins + 'px';
  navbarNav.style.marginBottom = navbarMargins + 'px';

  button.addEventListener('click', function() {
    if (navbar.classList.contains('navbar--deploy')) {
      return;
    } else {
      navbar.classList.add('navbar--deploy');
      navbarNav.style.removeProperty('margin-top');
      navbarNav.style.removeProperty('margin-bottom');

      button.classList.add('navbar__button--active');

      background.classList.add('background--active');

      navbar.addEventListener('transitionend', function() {
        var contentHeight = navbar.scrollHeight;
        navbar.style.height = contentHeight + 'px';
      }, { once: true });

      document.body.style.overflowY = 'hidden';
    }
  });

  back.addEventListener('click', function() {
    navbar.classList.remove('navbar--deploy');

    navbarNav.style.marginTop = navbarMargins + 'px';
    navbarNav.style.marginBottom = navbarMargins + 'px';

    button.classList.remove('navbar__button--active');

    background.classList.remove('background--active');

    navbar.style.removeProperty('height');

    document.body.style.removeProperty('overflow-y');
  });
});
